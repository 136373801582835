.about_icon_items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  text-align: center;
}

.about_icon_items .icon_section img {
  max-width: 100px;
}

.about_icon_items .icon_section h2 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 5px;
  margin-top: 15px;
}

.about_icon_items .icon_section p {
  font-size: 18px !important;
}

p.Iconcard_desc a {
  text-decoration: none;
  color: #000;
}

p.Iconcard_desc a:hover {
  color: var(--primary);
}

section.about_icons + .image_with_text_section {
  padding-top: 0;
}

section.about_icons + .image_with_text_section .row {
  align-items: center;
}
