.registeration_form .primaryButton {
  width: 100% !important;
  max-width: unset !important;
}

.registeration_form .primaryButton + .form-text {
  text-align: center;
  margin-top: 10px;
}

.registeration_form form h2.sec_heading {
  text-align: center;
  font-size: 35px;
  margin-bottom: 20px;
}

.registeration_form .mb-3 + small {
  margin-bottom: 10px;
}
