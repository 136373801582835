@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Barlow";
  src: url("../public/fonts/Barlow-Regular.ttf");
}

/* @font-face {
  font-family: "Barlow-med";
  src: url("../public/fonts/Barlow/Barlow/Barlow-Medium.ttf");
} */

body {
  font-family: "Barlow" !important;
}

:root {
  --primary: #25509e;
  --secondary: #0998dc;
  --black: #212529;
}

.container {
  max-width: 1260px !important;
}

section {
  padding-top: 50px;
  padding-bottom: 50px;
}

/* Policy pages */
.policy_section {
  padding-top: 50px;
  padding-bottom: 50px;
}

.policy_section .container {
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  section {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}

@media screen and (max-width: 480px) {
  section.image_with_text_section .content_section {
    margin-top: 20px !important;
  }
}

@media screen and (min-width: 768px) {
  .image_with_text_section .image_section img {
    height: 500px;
    object-fit: cover;
  }
}
