.registeration_form {
  max-width: 500px;
  margin: 0 auto;
}

.registeration_form form {
  display: flex;
  flex-direction: column;
}

.registeration_form .logoBrand {
  text-align: center;
}

.registeration_form form > a {
  width: 100% !important;
  max-width: unset !important;
  margin: 10px 0;
}

a.primaryButton + small {
  text-align: center;
}

form input {
  height: 50px !important;
  min-height: unset !important;
  border-radius: 100px !important;
  padding: 0 20px !important;
}
/* changs by HG */
.error-message {
  font-size: 12px;
  color: #cf0b0bf5;
  font-weight: 500;
  padding-left: 5px;
}
