.onsite_install_form.container {
  padding: 40px 10px;
}

.onsite_install_form.container h2.sec_heading {
  margin-bottom: 30px;
  text-align: center;
}

.onsite_form form {
  max-width: 700px;
  margin: 0 auto;
}

label.form-check-label a {
  color: #212529;
}

@media screen and (min-width: 768px) {
  .onsite_form form .col2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
  }
}

.onsite_form .react-datepicker__input-container input {
  width: 100%;
  border: 1px solid #ced4da;
}

.onsite_form .form-check-input[type="checkbox"] {
  height: 20px !important;
  width: 20px !important;
  padding: unset !important;
}

.onsite_form button.primaryButton.btn.btn-primary {
  width: 100% !important;
  max-width: unset !important;
}

input[type="file"]::file-selector-button {
  height: 55px;
  width: 40%;
  margin-left: -20px;
}
