.breadscrumb {
  background: #f3f3f3;
  padding-top: 20px;
  padding-bottom: 20px;
}

.breadscrumb .pageTitle {
  margin: 0;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}
