.support_form .registeration_form input,
.support_form .registeration_form textarea {
  border-radius: 10px !important;
}

section.support_form img {
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

section.support_form h2.sec_heading {
  font-size: 35px;
}

section.support_form h4.sec_title {
  color: var(--black);
}

section.support_form ul.social_icons li {
  margin-right: 10px;
}

@media screen and (max-width: 767px) {
  section.support_form .registeration_form {
    margin-top: 30px;
  }
}
