section.accordion_section .row {
  max-width: 900px;
  margin: 0 auto;
}

section.accordion_section .row .sec_heading {
  text-align: center;
  margin-bottom: 30px;
}

section.accordion_section h2.accordion-header button {
  font-size: 18px;
  font-weight: 600;
}

section.accordion_section .accordion-item {
  margin-bottom: 10px;
  border: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px !important;
  overflow: hidden;
}

section.accordion_section .accordion {
  --bs-accordion-active-color: var(--primary);
}

.accordion-body .content sup {
  vertical-align: -webkit-baseline-middle;
}

.accordion-collapse {
  height: 0;
  transition: 0.5s all;
}

.accordion-collapse.collapse.show {
  height: auto;
}
