section.Dspricing_section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.pricing_cards_section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.pricing_cards.card {
  border: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 18px;
  overflow: hidden;
}

.pricing_cards.card .card-body {
  position: relative;
  padding-top: 70px;
  text-align: center;
}

.pricing_cards.card .card-body .card-title {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  /* background: red; */
  text-align: center;
  padding: 15px;
  color: #fff;
  text-transform: uppercase;
  background-image: linear-gradient(to right, var(--primary), var(--secondary));
}

.pricing_cards span.price_amt {
  font-size: 45px;
  font-weight: 700;
}

.pricing_cards span.per_month {
  font-size: 20px;
}

.pricing_cards.card .card-body {
  padding-bottom: 90px;
}

.pricing_cards.card .bottom_links {
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
}

.pricing_cards.card span.cent_price {
  font-size: 20px;
}

.pricing_cards span.price_symbol {
  font-size: 20px;
  font-weight: 600;
  margin-right: 5px;
}

.pricing_cards a.primaryButton {
  display: block;
  margin: 0 auto;
}

.pricing_cards span.sub_heading {
  font-size: 16px;
  font-weight: 500;
}

.pricing_cards p.price_sec.card-text {
  margin: 0;
}

.pricing_cards .bottom_links a {
  margin-bottom: 10px;
}

.pricing_cards ul.features_list li {
  text-align: left;
}

.pricing_cards ul.features_list {
  padding-left: 20px;
  list-style: none;
}

.pricing_cards ul.features_list li svg {
  margin-right: 10px;
  font-size: 22px;
  fill: var(--primary);
}

/* Pricing left content  */

.pricing_content > h2.sec_heading {
  margin-bottom: 30px;
}

.pricing_content .content {
  max-width: 450px;
}
.pricing_content .content li {
  margin-bottom: 5px;
}

.pricing_content {
  text-align: center;
  margin-bottom: 50px;
}

.pricing_content .content {
  margin: 0 auto;
}

.pricing_content h2 {
  margin-bottom: 15px !important;
}

p.price_sec.card-text {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.pricing_cards span.price_amt.custom_text {
  text-transform: uppercase;
}

.pricing_cards span.price_amt.custom_text {
  text-transform: uppercase;
}

.pricing_cards ul.features_list li {
  margin-bottom: 5px;
}

.pricing_cards ul.features_list {
  margin-top: 15px;
  margin-bottom: 24px;
}

.pricing_cards ul.features_list li a {
  color: inherit;
}

.pricing_cards span.all-feature-from {
  font-weight: 600;
}

.registerForm_section {
  padding-top: 80px;
  padding-bottom: 50px;
}

.pricing_cards ul.features_list li {
  display: flex;
  align-items: flex-start;
}

.pricing_cards ul.features_list li svg {
  flex: 0 0 24px;
}

@media screen and (max-width: 1223px) {
  .ds_cards {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr)) !important;
  }
}

@media screen and (max-width: 863px) {
  .ds_cards .iconCards:nth-child(2),
  .ds_cards .iconCards:nth-child(4) {
    transform: unset !important;
  }
}

@media screen and (max-width: 767px) {
  .how-it-works .card_section {
    padding: 20px !important;
  }

  .useofDS_content_sec {
    padding-left: 20px !important;
    margin-bottom: 30px;
  }

  section.useof_digi_signage .row {
    flex-direction: column-reverse;
  }
}
