h2.sec_heading {
    font-size: 45px;
    font-weight: 700;
    line-height: 50px;
  }

  h4.sec_title {
    font-size: 24px !important;
    font-weight: 700;
    color: var(--primary);
  }
  
  h3.sec_subheading {
    font-size: 16px;
  }
  