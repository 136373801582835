.application_section .primaryButton {
  position: relative;
  left: 50%;
}

.application_section .section_heading {
  text-align: left;
  color: #333;
  font-weight: 500;
  font-size: 14px;
}

.application_section .section_subheading {
  font-size: 45px !important;
  color: #333 !important;
  text-align: left;
}

.section_top_content {
  max-width: 750px;
}

.application_cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  margin-top: 10px;
}

.application_cards .card img {
  border-radius: 0 !important;
}

.application_cards .card {
  border: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
  overflow: hidden;
}

.application_cards .card a {
  text-decoration: none !important;
}

.application_cards .card .card-title {
  color: #333;
  font-size: 20px;
  font-weight: 700;
}

.application_cards .card .card-title:hover {
  color: var(--primary) !important;
}

.application_cards p.card-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.application_section .primaryButton {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 35px;
}

.application_section {
  background-size: cover !important;
  background-position: right center !important;
}

.application_cards .card img {
  height: 190px !important;
  object-fit: cover;
}

.application_section .card_image {
  overflow: hidden;
}

.application_cards .card:hover .card_image img {
  transform: scale(1.05) !important;
}

.application_cards .card .card_image img {
  transition: 0.5s all;
}

.application_cards .section_short_desc * {
  font-size: 16px;
  font-weight: 400;
}
