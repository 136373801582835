thead tr th:first-child {
  width: 10% !important;
}

thead tr th:nth-child(3) {
  width: 200px;
}

tbody tr td {
  vertical-align: middle;
}
