.service_section {
  background-image: linear-gradient(
    to right,
    var(--primary),
    var(--secondary)
  ) !important;
}

.service_section h2.sec_heading {
  text-align: center;

  color: #fff;
}

.service_section h3.sec_subheading {
  text-align: center;

  color: #fff;
}

.service_cards {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 20px;
  padding-top: 50px;
}

.service_cards .card {
  background: transparent !important;
  border: none;
}

.service_cards .card_image {
  background: #fff;
  max-width: 125px;
  padding: 25px;
  border-radius: 100%;
  margin: 0 auto;
}

.service_cards .card-body .card-title {
  text-align: center;
  color: #fff;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.service_cards a {
  text-decoration: none;
}

.service_cards .card:hover .card_image,
.service_cards .card:hover .card_image img {
  transform: rotateY(180deg);
}

.service_cards .card .card_image,
.service_cards .card .card_image img {
  transition: 0.5s;
}

@media screen and (max-width: 523px) {
  .service_cards {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

@media screen and (max-width: 480px) {
  h2.sec_heading {
    font-size: 30px !important;
    line-height: 35px;
  }
}
