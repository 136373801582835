img.animated_image {
  position: absolute;
  -webkit-animation: infinite animated-item linear;
  animation: infinite animated-item linear;
  left: 25%;
  /* bottom: -8%; */
  -webkit-animation-duration: 15s;
  animation-duration: 15s;
  z-index: -1;
}

@keyframes animated-item {
  0% {
    transform: rotate(0) scale(1);
    opacity: 1;
  }
  33% {
    transform: rotate(120deg) scale(0.3);
    opacity: 0.3;
  }
  40% {
    opacity: 0.7;
  }
  66% {
    transform: rotate(240deg) scale(0.8);
    opacity: 0.8;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    transform: rotate(360deg) scale(1);
    opacity: 1;
  }
}

img.animated_image2 {
  position: absolute;
  -webkit-animation: infinite animated-item2 linear;
  animation: infinite animated-item2 linear;
  left: 70%;
  bottom: 10%;
  -webkit-animation-duration: 19s;
  animation-duration: 19s;
}

@keyframes animated-item2 {
  0% {
    transform: rotate(360deg) scale(1);
    opacity: 1;
  }
  33% {
    transform: rotate(240deg) scale(0.8);
    opacity: 0.3;
  }
  40% {
    opacity: 0.7;
  }
  66% {
    opacity: 0.8;
  }
  75% {
    transform: rotate(120deg) scale(0.3);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1);
    opacity: 1;
  }
}

.banner_section {
  position: relative;
}

.banner-button-group a {
  margin: 0 10px;
}

.banner_content.col {
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}

h1.banner_heading {
  font-size: 65px;
  font-weight: 800;
  color: #333;
  text-transform: uppercase;
  line-height: 70px;
}

.banner_section {
  padding-top: 80px;
  padding-bottom: 60px;
  background-position: center center;
  background-size: cover !important;
}

.banner_section .subheading {
  font-size: 18px;
  line-height: 30px;
  color: #333;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 30px;
}

.banner-button-group a.btn {
  max-width: 170px !important;
  width: 100% !important;
  margin: 0 10px;
  border-radius: 100px;
  line-height: 35px;
}

.bottom_text {
  margin-top: 25px;
}

.bottom_text p {
  font-size: 13px;
}

@media screen and (max-width: 480px) {
  h1.banner_heading {
    font-size: 45px;
    line-height: 50px;
  }

  .banner_section {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
