/* Inner page Banner  */

.page-header-banner {
  padding-top: 80px;
  padding-bottom: 80px;
}

.page-header-banner .banner_heading,
.page-header-banner .subheading {
  color: #fff;
}

.page-header-banner:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  position: absolute;
  background: #000;
  z-index: -1;
  opacity: 0.8;
}

.page-header-banner {
  position: relative;
  z-index: 1;
}

.page-header-banner .banner-button-group {
  margin-top: 30px;
}

.page-header-banner h4.pageTitle {
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.page-header-banner .outlineButton {
  text-decoration: none;
  color: #fff;
  padding: 10px !important;
  max-width: 180px !important;
  width: 100% !important;
  display: inline-block;
  line-height: 28px;
  background-image: transparent;
  border-radius: 100px;
  border: 1px solid #fff;
  text-align: center;
  font-weight: 600;
  transition: 0.5s all;
}

.page-header-banner .outlineButton:hover {
  background-image: linear-gradient(to right, var(--secondary), var(--primary));
  color: #fff !important;
  background-position: 178px;
  border-color: var(--primary);
}
