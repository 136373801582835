.primaryButton {
  text-decoration: none;
  color: #fff;
  padding: 10px !important;
  max-width: 180px !important;
  width: 100% !important;
  display: inline-block;
  line-height: 30px;
  background-image: linear-gradient(to right, var(--primary), var(--secondary));
  border-radius: 100px;
  text-align: center;
  font-weight: 600;
  transition: 0.5s all;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.primaryButton:hover {
  color: #fff !important;
}

.primaryButton:before {
  content: "";
  height: 100%;
  width: 0;
  position: absolute;
  left: 0;
  top: 0;
  background-image: linear-gradient(to right, var(--primary), var(--secondary));
  z-index: -1;
  transition: 0.6s all;
}

.primaryButton:hover::before {
  width: 100%;
}

.outlineButton {
  text-decoration: none;
  color: var(--primary);
  padding: 10px !important;
  max-width: 180px !important;
  width: 100% !important;
  display: inline-block;
  line-height: 28px;
  background-image: transparent;
  border-radius: 100px;
  border: 1px solid var(--primary);
  text-align: center;
  font-weight: 600;
  transition: 0.5s all;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.outlineButton:hover {
  color: #fff !important;
}

.outlineButton:before {
  content: "";
  height: 100%;
  width: 0;
  position: absolute;
  left: 0;
  top: 0;
  background-image: linear-gradient(to right, var(--primary), var(--secondary));
  z-index: -1;
  transition: 0.6s all;
}

.outlineButton:hover::before {
  width: 100%;
}

button.primaryButton {
  border: none;
}
