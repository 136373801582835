.registeration_form .col2 {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
}

.registeration_form .col2 select {
  height: 50px;
  border-radius: 10px;
}
