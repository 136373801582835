ul.social_icons {
  display: flex;
  list-style: none;
  margin: 0;
}

ul.social_icons svg {
  color: #333;
}

ul.social_icons a:hover svg {
  color: var(--primary);
}
