header#header > .navbar {
  padding-top: 15px;
  padding-bottom: 15px;
}

header#header nav .container .navbar-brand,
header#header nav .container .header_button_section {
  flex: 0 0 180px;
}

header#header div#offcanvasNavbar-expand-md {
  flex: 1 1 auto;
}

header#header .offcanvas-body {
  justify-content: center;
}

header#header .navbar-nav a.nav-link:hover {
  color: var(--primary);
  cursor: pointer;
}

header#header .navbar-nav a.nav-link {
  color: #333;
  margin: 0 10px;
  font-weight: 500;
  font-size: 14px;
  padding: 0 3px;
}

a.header_action_btn {
  background-image: linear-gradient(to right, var(--primary), var(--secondary));
  border: none;
  border-radius: 100px;
  line-height: 30px;
  max-width: 180px !important;
  width: 100%;
  transition: 0.5s all;
  font-weight: 400 !important;
  color: #fff !important;
}

.header_button_section {
  text-align: right;
}

a.header_action_btn:hover {
  background-image: linear-gradient(to right, var(--secondary), var(--primary));
}

/* Header menu  */

.navbar-expand-md .navbar-nav .dropdown-menu {
  padding: 0 !important;
  margin: 0;
}

/* @media screen and (max-width: 1080px) {
  .navbar-expand-md .navbar-toggler {
    display: block !important;
  }
} */

@media screen and (min-width: 768px) {
  .dropdown-menu {
    display: unset;
    visibility: hidden;
    opacity: 0;
    /* padding: 0; */
    border: none;
    background: transparent !important;
    z-index: -1;
  }

  .mega_menu_animation {
    background: #fff;
  }

  .dropdown-menu.show {
    display: unset;
    visibility: visible !important;
    opacity: 1 !important;
    z-index: 99999 !important;
  }
  .dropdown-menu .mega_menu_animation {
    padding: 25px !important;
    box-shadow: 0 16px 32px 0 rgb(37 37 37 / 16%);
    border: none !important;
    position: absolute;
    top: 100%;
    padding-top: 16px;
    transform: rotateX(90deg);
    transform-origin: top center;
    transition: all 0.3s ease-in-out 0s;
    border-radius: 12px;
  }

  .mega-menu.sub-menu .dropdown-menu .mega_menu_animation {
    left: 50% !important;
    transform: rotateX(90deg) translateX(-50%) !important;
  }

  .sub-menu.nav-item:hover .dropdown-menu .mega_menu_animation {
    transform: rotateX(0) !important;
  }

  .mega-menu.sub-menu.nav-item:hover .dropdown-menu .mega_menu_animation {
    transform: rotateX(0) translateX(-50%) !important;
  }

  .mega-menu.grid-3 .dropdown-menu .menu-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .mega-menu.grid-2 .dropdown-menu .menu-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .dropdown-menu.show a {
    min-width: 250px;
    padding: 10px;
    text-decoration: none;
    color: #333;
    font-size: 14px;
  }

  .mega-menu .dropdown-menu.show a {
    padding: 18px !important;
  }

  .dropdown-menu.show .menu-wrap {
    display: grid !important;
  }

  .dropdown-menu.show .submenu-title {
    color: var(--primary);
    font-weight: 600;

    display: block;
  }

  .mega-menu .dropdown-menu.show .submenu-title {
    margin-bottom: 5px !important;
  }

  .dropdown-menu.show p.submenu-item-desc {
    margin: 0;
    color: #333;
    font-weight: 400;
  }

  .dropdown-menu.show a:hover {
    background: #2355a214;
  }

  .mega-menu .dropdown-menu.show a {
    border-radius: 5px !important;
  }

  .dropdown-menu.show a {
    border-radius: 10px !important;
  }
}

@media screen and (max-width: 1024px) {
  .header_button_section {
    display: none !important;
  }

  header#header .offcanvas-body {
    justify-content: flex-end !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 959px) {
  header#header .navbar-nav a.nav-link {
    margin: 0 5px !important;
    font-size: 14px !important;
  }
}

@media screen and (max-width: 767px) {
  p.submenu-item-desc {
    display: none;
  }

  .menu-wrap a {
    display: block;
    text-decoration: none;
    color: inherit;
    margin-bottom: 10px;
  }

  .dropdown-menu.show {
    border: none;
  }

  .menu-wrap {
    padding-left: 10px;
  }

  .nav-item > a {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    font-weight: 600;
  }

  a.nav-link {
    padding: 10px;
    border-bottom: 1px solid #eee;
    font-weight: 600;
  }

  .dropdown-menu a {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }

  .navbar-toggler {
    border: none;
  }

  .offcanvas-body {
    padding-top: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1080px) {
  header#header .navbar-nav a.nav-link {
    margin: 0 4px !important;
    font-size: 12px !important;
  }

  nav.navbar.navbar-expand-md.navbar-light img {
    max-width: 125px !important;
  }
}

@media screen and (min-width: 1081px) and (max-width: 1200px) {
  nav.navbar.navbar-expand-md.navbar-light img {
    max-width: 125px !important;
  }

  .header_button_section {
    flex: 0 0 120px !important;
  }

  .header_button_section a {
    font-size: 14px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}
