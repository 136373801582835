@media screen and (min-width: 768px) {
  .services_sections section:nth-child(even) .row {
    flex-direction: row-reverse;
  }

  .services_sections section:nth-child(even) .row .content_section {
    padding-left: unset !important;
    padding-right: 30px;
  }
}

.services_sections section {
  margin-bottom: 30px;
  padding: 0;
}

.services_sections {
  padding-top: 50px;
  padding-bottom: 50px;
}

.services_sections .image_with_text_section .row {
  align-items: center;
}

@media screen and (max-width: 767px) {
  .services_sections section {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}
