.payement_status {
  text-align: center;
  min-height: 500px !important;
  display: flex;
  align-items: center;
}

.payement_status h2 {
  font-size: 40px;
  font-weight: 600;
}

.payement_status p .order_id {
  font-weight: 600;
}
