.loader {
    border: 16px solid rgb(243, 243, 243); /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
  }
  .loader-content{
    position: fixed;
    /* z-index: 100000; */
    background-color: rgba(0,0,0,0.4);
    width: 100vh;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    bottom: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }