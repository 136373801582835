.bottom_callaction_section a.outlineButton {
  border-color: #fff;
  color: #fff;
}

.bottom_callaction_section a.outlineButton:hover {
  background: #fff !important;
  color: #fff !important;
}

.bottom_callaction_section a {
  margin: 0 10px;
}

.bottom_callaction_section a.primaryButton {
  background: #fff;
  color: var(--primary);
}

.bottom_callaction_section a.primaryButton:hover {
  background-image: linear-gradient(to right, var(--secondary), var(--primary));
}

.bottom_callaction_section {
  background-image: linear-gradient(
    to right,
    var(--primary),
    var(--secondary)
  ) !important;
}

.bottom_callaction_section .row {
  align-items: center;
}

.bottom_callaction_section .section_top_content {
  color: #fff;
}

.bottom_callaction_section .section_top_content h2 {
  font-size: 45px;
  font-weight: 800;
  line-height: 30px;
}

.bottom_callaction_section .section_top_content h3.section_subheading {
  font-size: 32px;
}

.bottom_callaction_section .section_top_content .section_desc {
  margin-bottom: 0;
}

.bottom_callaction_section .button_groups {
  text-align: right;
}

.bottom_callaction_section .button_groups button {
  padding: 0;
  margin-left: 10px;
}

.bottom_callaction_section .button_groups button a {
  color: #fff;
  text-decoration: none;
}

.bottom_callaction_section .button_groups button a {
  max-width: 180px !important;
  width: 100% !important;
  font-size: 20px;
  font-weight: 400 !important;
  min-width: 180px !important;
  display: block;
  padding: 12px;
  line-height: 35px;
}

.bottom_callaction_section .button_groups button {
  border-radius: 100px;
}

.bottom_callaction_section .button_groups button {
  border: none !important;
  background: transparent;
}

ul.footer_menus li {
  list-style: none;
}

.footer_bottom_section .footer_block h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.footer_bottom_section .footer_block .menu_items a {
  font-size: 16px;
  color: #333;
  text-decoration: none;
  font-weight: 500;
}

.footer_bottom_section .footer_menus {
  padding: 0;
  margin: 0;
}

.footer_bottom_section ul.footer_menus li {
  margin-bottom: 8px;
}

.footer_bottom_section ul.footer_menus li a:hover {
  color: var(--primary) !important;
}

.footer_bottom_section ul.contactDetails {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer_bottom_section ul.contactDetails li {
  margin-bottom: 8px;
}

.footer_bottom_section ul.contactDetails li a {
  color: #333;
  text-decoration: none;
}

.footer_bottom_section ul.contactDetails li a:hover {
  color: var(--primary);
}

/* Copyright  */

.copyright {
  background: #eaeaea;
  padding: 15px 0;
}

.copyright p {
  margin: 0;
  font-size: 14px;
}

.copyright .row {
  align-items: center;
}

.social_icon_footer {
  text-align: right;
}

.social_icon_footer a {
  margin: 0 10px;
  color: #333;
}

.social_icon_footer a:last-child {
  margin-right: 0;
}

.social_icon_footer a:hover {
  color: var(--primary) !important;
}

.social_icon_footer ul.social_icons {
  padding: 0;
  justify-content: flex-end;
}

.social_icon_footer ul.social_icons li {
  margin-left: 10px;
}

.footer_bottom_section ul.contactDetails li {
  display: flex;
  align-items: flex-start;
}

.footer_bottom_section ul.contactDetails li svg {
  margin-right: 5px !important;
  font-size: 20px !important;
  position: relative;
  top: 3px;
  flex: 0 0 20px;
}

.copyright p {
  text-align: center;
}

.footer_bottom_section ul.contactDetails li:first-child a {
  word-break: break-all;
}

@media screen and (max-width: 930px) {
  .bottom_callaction_section .button_groups {
    text-align: center;
    margin-top: 20px;
  }

  .footer_bottom_section ul.contactDetails li:first-child a {
    font-size: 12px;
    word-break: break-all;
  }

  .section_top_content {
    text-align: center;
  }

  .bottom_callaction_section .row {
    display: block;
  }

  .bottom_callaction_section .row .col-md-6 {
    width: 100%;
  }

  .bottom_callaction_section .section_top_content {
    margin: 0 auto;
  }

  .footer_bottom_section ul.contactDetails li svg {
    max-width: 22px !important;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .copyright {
    text-align: center;
  }

  .social_icon_footer .social_icons {
    justify-content: center !important;
    text-align: center;
    margin-top: 10px;
  }

  .footer_bottom_section .row .footer_block:first-child {
    width: 100%;
    flex: 0 0 100%;
    text-align: left;
    margin-bottom: 30px;
  }

  .footer_bottom_section .row .footer_block {
    width: 50%;
    margin-bottom: 15px;
  }

  .footer_bottom_section {
    padding-top: 30px !important;
    padding-bottom: 20px !important;
  }
}

@media screen and (max-width: 480px) {
  .bottom_callaction_section .section_top_content h3.section_subheading {
    font-size: 22px;
    line-height: 30px;
  }
}
