.about_section {
  background-position: 100% 100px;
  background-repeat: no-repeat;
  background-size: 40%;
}

.about_section .banner_content {
  text-align: left !important;
}

.about_section h2.sec_heading,
.about_section h3.sec_subheading {
  text-align: left !important;
  color: #fff !important;
}

.about_section h2.sec_heading {
  margin-bottom: 40px;
}

.about_section p.secDesc {
  color: #fff !important;
}

.about_section .secDesc { 
  color: #fff !important;
}
.about_section .primaryButton {
  margin: 0;
  margin-top: 30px;
}

.about_section .sec_subheading {
  margin-bottom: 20px;
  color: #fff !important;
}

.about_section {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

.about_section:before {
  content: "";
  width: 50%;
  height: 100%;
  position: absolute;
  background: url(../../../../public/images/bannerbg.png);
  left: 0;
  top: 0;
  z-index: -1;
  background-size: cover;
  background-position: center right;
  opacity: 0.4;
}

.about_section {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .about_section {
    background-position: bottom right;
    background-size: contain;
    padding: 50px 0 !important;
  }

  .about_section:after {
    content: "";
    height: 100%;
    width: 100%;
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
    z-index: -1;
  }
}
