.brand_section .section_top_content h2 {
    max-width: unset;
    color: #333;
    margin-bottom: 50px;
  }
  
  .brand_section .section_top_content {
    margin: 0 auto;
    text-align: center;
  }
  
  .brand_section .imageGroup img {
    max-width: 200px;
    width: 100%;
    height: 80px;
    object-fit: contain;
  }
  
  .brand_section .imageGroup span {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px;
    border-radius: 15px;
    transition: 0.3s;
  }
  
  .brand_section .imageGroup span:hover {
    background-image: linear-gradient(to right, var(--primary), var(--secondary));
  }
  
  .brand_section .imageGroup {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 20px;
  }
  
  .brand_section .imageGroup > span:hover img {
    filter: invert(1);
  }