.banner_section.featuresBanner:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0.8;
}

.banner_section.featuresBanner {
  position: relative;
  z-index: 1;
}

.banner_section.featuresBanner .banner_content,
.banner_section.featuresBanner .banner_content h1,
.banner_section.featuresBanner .banner_content p {
  color: #fff !important;
}

.banner_section.featuresBanner .banner_content a.outlineButton {
  border-color: #fff;
  color: #fff;
}

.banner_section.featuresBanner {
  padding-bottom: 80px;
}

.feature_section .section_heading {
  text-align: center;
  font-size: 45px;
  font-weight: 800;
}

.feature_section p.section_subheading {
  font-size: 18px;
  text-align: center;
}

.feature_section .section_heading {
  text-align: center;
  font-size: 45px;
  font-weight: 800;
}

.feature_section p.section_subheading {
  font-size: 18px;
  text-align: center;
}

.features_cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  margin-top: 20px;
}

.features_cards .card {
  z-index: 1;
  border: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
  overflow: hidden;
}

.features_cards .card .card_image {
  width: 60px;
  height: 60px;
  padding: 5px;
}

.features_cards .card-body .card_title_name {
  position: absolute;
  padding-left: 60px;
  top: 18px;
  text-decoration: none;
  color: var(--primary);
  /* font-weight: 900 !important; */
}

.features_cards .card-body a .card-title {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;
}

.features_cards .card-body {
  padding-top: 9px;
}

.features_cards .card:before {
  content: "";
  height: 75px;
  width: 75px;
  border-radius: 100%;
  left: -10px;
  top: -10px;
  position: absolute;
  z-index: -1;
  background-image: linear-gradient(to right, var(--primary), var(--secondary));
}

.features_cards .card-body .primaryButton {
  max-width: unset !important;
  background: transparent;
  color: var(--primary);
  padding: 0 !important;
  width: unset !important;
}

.features_cards .card-body .primaryButton:hover {
  color: var(--secondary) !important;
}

.features_cards p.card-text {
  margin-bottom: 5px;
}

.features_cards .card-body .primaryButton:hover {
  color: var(--secondary) !important;
}

.features_cards p.card-text {
  margin-bottom: 5px;
}

.features_cards .card:after {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  right: -100px;
  bottom: -100px;
  background: var(--secondary);
  position: absolute;
  content: "";
  z-index: 1;
  opacity: 0.1;
  transition: 0.5s all;
}

.features_cards .card:hover::after {
  right: -20px;
  bottom: -20px;
}

.features_cards span.feature_type {
  position: absolute;
  right: 20px;
  top: 20px;
  text-transform: uppercase;
  background-image: linear-gradient(to right, var(--primary), var(--secondary));
  color: #fff;
  padding: 2px 15px;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 600;
}
