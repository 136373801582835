.checkout_form .col2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
}

.checkout_form input {
  border-radius: 8px !important;
}

#checkout_section h4.sec_title {
  margin-bottom: 30px !important;
}

/* Order table  */

.order-table {
  background: #f3f3f3;
  border-radius: 10px;
  overflow: hidden;
}

.order-table table {
  margin: 0;
}

.order-table tr td,
.order-table tr th {
  padding: 15px !important;
}

tr.order-subtotal td:last-child,
tr.order-total td:last-child,
tr.order-details {
  background: #fff;
}

.order-table td,
.order-table th {
  border: none;
}
.order-table tr {
  margin-bottom: 2px !important;
}

.order-table th {
  text-transform: uppercase;
}

/* Payment options  */

li.payment_item .form-text {
  display: block;
}

.payment_methods li {
  list-style: none;
}

.payment_methods li input {
  margin-right: 10px;
}

.payment_methods ul {
  padding: 0;
  margin-top: 20px;
}

.payment_methods {
  background: #f3f3f3;
  padding: 15px;
  border-radius: 10px;
  margin-top: 20px;
}

.payment_methods ul {
  margin: 0;
}

li.payment_item {
  margin-bottom: 20px;
}

li.payment_item:last-child {
  margin-bottom: 0;
}

.StripeElement.StripeElement--empty {
  padding: 10px;
  background: #fff;
  border-radius: 5px;
}

.payment_methods li input.hide ~ small {
  display: none;
}

.payment_methods li input.hide ~ small {
  display: none;
}

.checkout_order_details .primaryButton {
  margin-top: 20px;
  width: 100%;
  max-width: unset !important;
}

form .payment_methods input[type="radio"] {
  height: unset !important;
}

.checkout_form > form {
  display: flex;
  grid-gap: 30px;
}

.checkout_form_left {
  flex: 0 0 55%;
}

.checkout_form_right {
  flex: 1 1 auto;
}

.checkout_details + .checkout_order_details {
  display: none !important;
}

.checkout_form_right button {
  width: 100%;
  display: block !important;
  max-width: unset !important;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  .checkout_form > form {
    display: block;
  }
}
