.col2_field {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
}

form.custom_contact_form {
  margin-top: 20px;
}

form.custom_contact_form input,
form.custom_contact_form textarea {
  border-radius: 10px !important;
}

ul.contact_details_li {
  margin-top: 30px;
  list-style: none;
  padding-left: 20px;
}

ul.contact_details_li svg {
  margin-right: 10px;
  font-size: 24px;
}

ul.contact_details_li li {
  margin-bottom: 20px;
}

.contact_detail_section h4.sec_title {
  color: inherit !important;
}

.contact_detail_section ul.social_icons {
  list-style: none;
  padding-left: 20px;
  display: flex;
  margin-top: 20px;
}

.contact_detail_section ul.social_icons li {
  margin-right: 15px;
}

.contact_detail_section ul.social_icons li a {
  color: #000;
}

.contact_detail_section ul.social_icons li a:hover {
  color: var(--primary);
}

ul.contact_details_li {
  margin-bottom: 30px;
}

ul.contact_details_li li a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 767px) {
  .contact_form {
    margin-bottom: 30px;
  }

  section.contact_section .row {
    flex-direction: column-reverse;
  }
}
