section.image_with_text_section {
  padding-top: 50px;
  padding-bottom: 50px;
}

section.image_with_text_section .sec_heading {
  margin-bottom: 20px;
}

section.image_with_text_section .primaryButton {
  margin-top: 20px;
}

@media screen and (min-width: 768px) {
  section.image_with_text_section .content_section {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) {
  section.image_with_text_section .sec_heading {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
